<template>
  <BaseTootlipModal v-if="bonus" :is-mobile="isMobile" :link="content.link">
    <template #title>
      {{ title }}
    </template>
    <template #subtitle>
      {{ content.subtitle }}
    </template>
    <TooltipModalBlock
      :title="t('bonuses.tooltipModalParameters')"
      data-t="parameters-block"
    >
      <TooltipModalBlockItem
        :termin="t('bonuses.tooltipRegularRewardCurrency')"
        :description="currencyCode"
      />
    </TooltipModalBlock>
  </BaseTootlipModal>
</template>

<script setup lang="ts">
import { useRegularBonusesStore } from '../../stores/useRegularBonusesStore'
import { useRegularBonus } from '../RegularBonusCard/useRegularBonus'
import BaseTootlipModal from '../TooltipModal/BaseTootlipModal.vue'
import TooltipModalBlock from '../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../TooltipModal/TooltipModalBlockItem.vue'

const props = defineProps<{
  id: string
}>()

const { t } = useI18n()

const { isMobile } = usePlatform()

const regularBonusesStore = useRegularBonusesStore()
const { completedBonuses } = storeToRefs(regularBonusesStore)

const bonus = computed(
  () => completedBonuses.value.filter((item) => item.id === props.id)[0],
)

const { isHiddenAmount, currencyCode, bonusAmountWithCurrency, content } =
  useRegularBonus(bonus)

const title = computed(
  () =>
    `${content.value.title} ${
      isHiddenAmount.value ? '' : bonusAmountWithCurrency.value
    }`,
)
</script>
